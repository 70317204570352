.chips {
  display: flex;
  flex: 1;
  gap: 12px;
}
.chips :global(.MuiChip-label) {
  font-size: 14px;
}
.chips :global(.MuiChip-icon) {
  font-size: 18px;
}

.chip {
  background-color: hsl(0, 0%, 90%);
  color: hsl(0, 0%, 30%);
}
.chipSelected,
.chipSelected:hover {
  background-color: hsl(0, 0%, 30%);
  color: white;
}
.chipSelected:focus {
  background-color: hsl(0, 0%, 20%);
}
