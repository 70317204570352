.wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.logo {
  width: 68px;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  gap: 4px;
  align-items: center;
}
