.switch:global(.MuiSwitch-root) {
  width: 48px;
  height: 24px;
  padding: 0;
}

.switch :global(.MuiSwitch-switchBase) {
  padding: 3px;
}

.switch :global(.MuiSwitch-switchBase):global(.Mui-checked) {
  transform: translateX(24px);
  color: white;
}

.switch :global(.MuiSwitch-switchBase):global(.Mui-checked) + :global(.MuiSwitch-track) {
  background-color: var(--green);
  opacity: 1;
}

.switch :global(.MuiSwitch-thumb) {
  height: 18px;
  width: 18px;
}

.switch :global(.MuiSwitch-track) {
  border-radius: 15px;
  height: 24px;
  background-color: #e6eaed;
  opacity: 1;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
