.contentWrapper {
  flex-grow: 1;
  width: calc(100% - 240px);
  padding: 80px 28px 0 28px;
}

.alert {
  width: 100%;
  z-index: 999;
}

@media screen and (max-width: 600px) {
  .contentWrapper {
    padding: 66px 8px 0 8px;
  }
}
