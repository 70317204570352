.colors {
  display: flex;
  margin-bottom: 20px;
}

.color_wrapper {
  display: flex;
  flex-flow: column;
  gap: 2.1333rem;
}
