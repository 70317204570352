/* TODO: this may need to be overwritten globally */
.form :global(.MuiOutlinedInput-root) {
  border-radius: 100px;
}

.form {
  display: flex;
  gap: 52px;
  margin: 20px 0;
}

.textFields {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 40%;
}
.roles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  gap: 16px 28px;
  font-size: 14px;
}
.roles > div {
  width: 45%;
}
.roles label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rolesLabel {
  width: 100px;
  font-size: 14px;
  font-weight: 500;
}
.roles p {
  font-size: 12px;
}
.actions {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-bottom: 16px;
}
