/* NAV BUTTON */
.nav {
  --primaryColor: #24305e;
  --primaryGrey: #4d4d4d;
  background-color: white;
  color: var(--primaryGrey);
  padding: 16px 24px 16px 16px;
  height: 56px;
  min-width: 56px;
  border-radius: 9999px;
}

.nav :global(.MuiButton-label) {
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  justify-content: flex-start;
  gap: 12px;
  text-transform: none;
}

.nav :global(.MuiButton-label) span {
  font-size: 14px;
  opacity: 1;
  transition: opacity 0.2s, visibility 0.3s;
}

@media screen and (min-width: 600px) {
  .nav.closed :global(.MuiButton-label) span {
    opacity: 0;
    visibility: hidden;
  }
}

.nav :global(.MuiButton-startIcon) {
  margin-right: 24px;
}

.nav:hover {
  color: var(--primaryGrey);
  background-color: rgba(0, 0, 0, 0.05);
}

.nav:focus {
  color: var(--primaryGrey);
  background-color: rgba(0, 0, 0, 0.1);
}

/* ACTIVE STATE */
.nav.active {
  color: var(--primaryColor);
  background-color: rgba(36, 48, 94, 0.1);
}

.nav.active :global(.material-icons),
.nav.howTo :global(.material-icons) {
  font-size: 24px;
  color: var(--primaryColor);
}

.nav.active :global(.MuiButton-label) {
  color: var(--primaryColor);
  font-weight: 600;
}

.nav.howTo :global(.MuiButton-label) {
  color: var(--primaryColor);
}
