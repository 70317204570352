.accountDetailsForm {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-auto-rows: 70px;
  column-gap: 1rem;
}

@media screen and (max-width: 768px) {
  .accountDetailsForm {
    grid-template-columns: 100%;
  }
}
