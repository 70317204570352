.brandedDomain {
  display: flex;
}

.input_wrapper {
  display: grid;
  grid-auto-rows: 70px;
  grid-template-columns: 350px auto;
  column-gap: 1rem;
}

.input_email {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
}

.checkmark {
  color: green;
}
