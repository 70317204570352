.wrapper {
  display: flex;
}

.radio_group :global(.MuiFormGroup-root) {
  height: 8.1333rem;
  width: 14rem;
}

.radio_boolean :global(.MuiFormGroup-root) {
  height: 5.4rem;
}

.tooltip {
  height: 1.6rem;
}
