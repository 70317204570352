.video_wrapper {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-flow: column;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.video_player {
  max-width: 65vw;
  width: 100%;
  border-radius: 20px;
  background-color: #ddd;
}

.hidden {
  display: none;
}

.countdown_text {
  position: absolute;
  font-size: 100px;
  color: white;
}

.error_text {
  position: absolute;
  color: red;
  font-size: 16px;
  font-weight: 700;
}

.screen_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  gap: 16px;
}

.toolbar button i {
  color: white;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader_text {
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.indicator {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 16px;
  width: 16px;
  border: 0;
  border-radius: 25px;
  outline: none;
}

.flip {
  position: absolute;
  top: 16px;
  left: 16px;
  height: 16px;
  width: 16px;
  border: 0;
  border-radius: 25px;
  outline: none;
}

.mirrored {
  transform: scaleX(-1);
}

.standby {
  background-color: grey;
}

.recording {
  background-color: red;
  animation: pulse 1.5s infinite linear;
}

.betaLabel {
  margin-left: 24px;
  letter-spacing: 3px;
  font-size: 10px;
  color: #0000ff;
  font-weight: 600;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}
