.maintenancePage {
  position: absolute;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100%;
  background-color: #ebf4ff;
  text-align: left;
}

.maintenancePageQuadrilateral {
  height: 100%;
  width: 100%;
  background: linear-gradient(#e53935, #c62828);
  clip-path: polygon(0% 0%, calc(50% - 164px) 0%, calc(50% - 420px) 100%, 0% 100%);
}

.maintenancePageContent {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.maintenancePageLogo {
  height: 15vh;
  vertical-align: middle;
}

.maintenancePageLogoWrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 6vh;
}

.maintenancePageLoginWrapper {
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
  padding: 20px;
  flex-flow: column;
}

.maintenance {
  background-color: yellow;
  width: 35%;
  min-width: 92px;
  padding: 2px;
  text-align: center;
  position: absolute;
  left: -3%;
  top: 42%;
  transform: rotate(45deg);
  font-size: 8px;
  font-weight: 700;
}
