.logo_wrapper {
  display: flex;
  cursor: pointer;
}

.logo {
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 50%;
}

.wrapper {
  height: 8.5rem;
  width: 8.5rem;
  border: 1px dashed #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.inner_circle {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 50%;
  position: relative;
}

.button {
  background-color: white;
  position: absolute;
  padding: 0.875rem;
  top: calc(50% - 1.625rem);
  left: calc(50% - 1.625rem);
}

.hidden_input {
  display: none;
}
