.row {
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  gap: 16px;
  margin-bottom: 16px;
}
.loader_wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 16px;
}

.loader_text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 4px;
  background-color: #fafafa;
}

.video_wrapper {
  width: 100%;
}
