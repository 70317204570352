.videoDoneOverlay {
  opacity: 1;
  transition: all 0.3s linear;
}

.videoFinished_playPauseButton {
  display: none !important;
}

.videoFinished_rangeInput {
  display: none !important;
}

.videoFinished_duration {
  display: none !important;
}

.videoPlaying_playPauseButton {
  opacity: 0;
  transition: all 0.3s linear;
}

.videoPlaying_rangeInput {
  opacity: 0;
  display: none;
  transition: all 0.3s linear;
}

.videoPlaying_duration {
  opacity: 0;
  transition: all 0.3s linear;
}

.videoPaused_playPauseButton {
  opacity: 1;
  transition: all 0.3s linear;
}

.videoPaused_rangeInput {
  opacity: 1;
  transition: all 0.3s linear;
}

.videoPaused_duration {
  opacity: 1;
  transition: all 0.3s linear;
}

/* on medium screens and up, show hover*/
@media (min-width: 576px) {
  .videoPlayPauseButton:hover {
    opacity: 1;
    transform: scale(1.1);
    transition: all 0.3s linear;
    cursor: pointer;
  }

  .videoContainer:hover .videoPlayPauseButton {
    opacity: 0.9;
    cursor: pointer;
  }

  .videoContainer:hover .videoRangeInput {
    opacity: 0.9;
    display: block;
    cursor: pointer;
  }
}

.videoRangeInput::-webkit-slider-runnable-track {
  cursor: pointer;
  background: hsla(0, 0%, 100%, 0.6);
  border-radius: 5px;
  width: 100%;
  height: 5px;
}

.videoRangeInput::-webkit-slider-thumb {
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  height: 21px;
  margin-top: -8px;
  -webkit-appearance: none;
  width: 21px;
  outline: none;
  border: none;
}

.videoRangeInput::-webkit-slider-thumb:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.3s linear;
}

/* on medium screens and up, show hover*/
@media (min-width: 576px) {
  .videoPlayPauseButton:hover {
    opacity: 1;
    cursor: pointer;
    transition: transform 0.2s;
    transform: scale(1.05);
  }
}

.progressBubbleShadow {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.1),
    0 3px 3px -2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

.story {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.story img {
  width: 70px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.story svg {
  fill: none;
  stroke-width: 3px;
  stroke-dasharray: 5;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: loading 4500ms ease-in-out infinite alternate;
}
@keyframes loading {
  100% {
    stroke: #fff;
    stroke-dasharray: 50;
    transform: rotate(200deg);
  }
}

.thumbnail-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 4px;

  overflow: hidden;

  /* Add the blur effect */
  filter: blur(23px);
  -webkit-filter: blur(23px);
  opacity: 0.7;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.topDownGradientBackDrop {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 10px, white 80px, white);
}

video::cue {
  font: 16px "Roboto", sans-serif;
}
