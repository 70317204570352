.page {
  width: 100%;
}

.header {
  display: flex;
  gap: 20px;
  align-items: center;
  line-height: 1rem;
  margin-bottom: 25px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.subtitle > .redIcon {
  color: red;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.regionSelect {
  min-width: 120px;
  width: fit-content;
}
