.button {
  border-radius: 18px;
  height: 36px;
  padding: 4px 16px;
  min-width: 52px;
  font-weight: 500;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-transform: uppercase;
  color: white;
}

.button:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

a.button:hover {
  color: white;
}

.button:global(.MuiButton-containedPrimary):global(.Mui-disabled) {
  color: #999999;
  background: rgba(0, 0, 0, 0.12);
}

.button:global(.MuiButton-outlined),
a.button:global(.MuiButton-outlined) {
  color: #24305e;
  border: 2px solid #24305e;
}

.button:global(.MuiButton-outlined):disabled {
  color: hsla(0, 0%, 70%);
  border-color: hsla(0, 0%, 70%);
}

.button:global(.MuiButton-textPrimary) {
  color: #24305e;
}

.button :global(.MuiButton-label) {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
}

.button :global(.MuiButton-startIcon) {
  margin-right: 0;
}

.button :global(.material-icons) {
  font-size: 24px;
  color: white;
}
