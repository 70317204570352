.box-shadow {
  box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.socialShareButton {
  height: 28px;
  border-radius: 4px;
  margin: 0px 5px;
  border: none;
  cursor: pointer;
}

.socialShareButtonText {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 500;
  height: 26px;
  line-height: 26px;
  margin: 0px 4px;
  text-align: center;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 35.1719px;
  zoom: 1;
}

.smsBtn {
  background-color: #5bc236;
}

.smsBtn:hover {
  background-color: green;
}

.twitterBtn {
  background-color: #1b95e0;
}

.twitterBtn:hover {
  background-color: #0c7abf;
}

.facebookBtn {
  background-color: #4267b2;
}

.facebookBtn:hover {
  background-color: #365899;
}

.noteButton {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 6px 16px;
  border: 0;
  font-size: 0.875rem;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  line-height: 1.75;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
  min-width: 150px;
}

.noteButton:hover {
  opacity: 0.7;
}

.noteButton:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
