.integration {
  border: 1px solid #cccccc;
  border-radius: 0.8rem;
  padding: 1.0667rem;
  min-height: 6.1333rem;
  min-width: 22.1333rem;
  position: relative;
}

.header {
  display: flex;
  margin-bottom: 1.0667rem;
}

.logo {
  height: 1.6rem;
  margin-right: 0.5333rem;
}

.title {
  margin: auto 0;
  font-weight: 400;
  font-size: 0.9333rem;
  line-height: 1.2rem;
}

.switch {
  position: absolute;
  top: 1.0667rem;
  right: 1.0667rem;
}

.key_field {
  width: 100%;
}

.key_field :global(.MuiInputBase-input) {
  padding: 0.3333rem 0.6667rem;
}
