.card {
  padding: 1.6rem;
  border-radius: 20px;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 1.0667rem;
}

.disabled {
  pointer-events: none;
  filter: grayscale(1) opacity(0.5);
}

.subtext {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.integrations_description {
  margin-bottom: 1.8667rem;
}

.text_wrapper {
  display: flex;
}

.text_label {
  display: flex;
  gap: 2rem;
}

.phone {
  color: var(--green);
}

.divider {
  margin: 1.75rem 0 1.75rem 0;
}

.button_wrapper {
  display: inline-block;
  position: relative;
  margin-top: 1.75rem;
}

.button_progress {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  color: white;
}
