.scheduledSendActionRow {
  display: flex;
}

.scheduledSendActionRow .spacer {
  flex: 1;
}

.scheduledSendEditButton {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .scheduledSendActionRow {
    flex-direction: column;
  }

  .scheduledSendActionRow .spacer {
    display: none;
  }

  .scheduledSendActionRow .scheduledSendButton {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .scheduledSendActionRow .scheduledSendEditButton {
    margin-left: 0;
  }
}
