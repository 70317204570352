.tableHead th {
  border: 0;
}

.tableCell {
  border: 0;
  font-size: 12px;
  font-weight: 700;
  color: hsl(217, 9%, 50%);
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: color var(--transition-duration) linear;
}

.tableCell.sortable:hover {
  color: hsl(217, 9%, 20%);
  cursor: pointer;
}

.tableCell :global(.material-icons) {
  font-size: 18px;
  opacity: 0;
  transition: opacity var(--transition-duration) linear;
}

.tableCell .active,
.tableCell:hover :global(.material-icons) {
  opacity: 1;
}
