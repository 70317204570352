.integrations {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-bottom: 1.6rem;
}
