.tabs {
  display: none;
  border-bottom: 1px #ddd solid;
  max-width: 100vw;
}
.bottomNavigation {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  box-shadow: 0px 3px 20px hsla(0, 0%, 0%, 0.07);
}
.bottomNavigation i {
  padding: 4px 20px;
  background-color: hsla(219, 63%, 35%, 0);
  border-radius: 100px;
}
.bottomNavigation :global(.Mui-selected) i {
  background-color: hsla(219, 63%, 35%, 0.1);
}

@media screen and (min-width: 600px) {
  .tabs {
    display: block;
  }
  .bottomNavigation {
    display: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .bottomNavigation i {
    transition: background-color 0.3s ease-in-out;
  }
}
