/* TODO: this may need to be overwritten globally */
.card :global(.MuiOutlinedInput-root),
.dialog :global(.MuiOutlinedInput-root) {
  border-radius: 100px;
}

.card {
  --radius-sm: 8px;
  --radius-lg: 20px;

  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1600px;
  border-radius: var(--radius-lg);
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}
.title {
  font-size: 22px;
  font-weight: 700;
}
.subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
}

.tools {
  display: flex;
  gap: 0.8rem;
}

.fixedTable th:nth-child(1) {
  width: 80px;
}
.fixedTable th:nth-child(2) {
  width: 220px;
}
.fixedTable th:nth-child(3) {
  width: 120px;
}
.fixedTable th:nth-child(4) {
  width: 200px;
}
.fixedTable th:nth-child(5) {
  width: 360px;
}
.fixedTable th:nth-child(6) {
  width: 140px;
}
.fixedTable th:nth-child(7) {
  width: 140px;
}
.fixedTable th:nth-child(8) {
  width: 140px;
}

.tableRow td {
  border: 0;
  font-size: 14px;
}
.actionButtons {
  white-space: nowrap;
}
.selectedRow td {
  background-color: hsl(0, 0%, 97%);
}
.selectedRow td:first-child {
  border-radius: var(--radius-sm) 0 0 0;
}
.selectedRow td:last-child {
  border-radius: 0 var(--radius-sm) 0 0;
}
.footer {
  font-size: 12px;
  margin: 16px 0 0 16px;
}

.filterGroup button {
  text-transform: none;
  font-size: 14px;
  font-weight: 700;
  border-radius: 36px;
  height: 36px;
  padding: 0 36px;
  margin-bottom: 24px;
}
.filterGroup :global(.Mui-selected) {
  color: hsl(230, 77%, 55%);
}

.avatar {
  background-color: hsl(230, 77%, 55%, 0.25);
}
.checkIcon {
  color: hsl(157, 100%, 39%);
}

.editUser {
  background-color: hsl(0, 0%, 95%);
  border-radius: 0 0 var(--radius-lg) var(--radius-lg);
  padding: 0 24px 0 16px;
  overflow: hidden;
}
.editUser h4 {
  font-weight: 700;
  margin: 16px 0;
}
