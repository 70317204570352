.dialog :global(.MuiPaper-root) {
  min-width: 0;
}

.title {
  width: 5rem;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1.0667rem;
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.input_wrapper {
  display: flex;
}

.color_preview {
  height: 5rem;
  width: 5rem;
  border-radius: 100%;
  margin-right: 2.2667rem;
  cursor: pointer;
  border: 1px solid #cccccc;
}

.button {
  margin: auto 0;
  border-radius: 20px;
  font-weight: 400;
}

.dialog_close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@media screen and (max-width: 768px) {
  .input_wrapper {
    flex-direction: column;
  }

  .button {
    margin-top: 15px;
  }
}
