.item {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.item + .item {
  border-top: 1px solid hsla(0, 0%, 90%);
}

.item.selected {
  background-color: hsl(0, 0%, 95%);
}

.item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.greenCheck {
  padding: 6px;
  color: green;
}

.details {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  gap: 2px;
}

.title {
  font-size: 16px;
  color: hsl(0, 0%, 30%);
  overflow-x: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.date {
  color: hsl(0, 0%, 50%);
  font-size: 12px;
}

.statuses {
  display: flex;
  gap: 8px;
  font-size: 12px;
}
.statuses i {
  font-size: 20px;
}
.statusOn {
  color: hsla(0, 0%, 30%, 1);
}
.statusOff {
  color: hsla(0, 0%, 30%, 0.4);
}
.assignedUser {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: hsl(0, 0%, 30%);
}
