.wrapper {
  padding: 0px 0px 10px 0px;
}

.description {
  padding-top: 5px;
  padding-bottom: 20px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
