/* LOCAL OVERWRITES: meant to be global changes in the future */

/* TODO: this may need to be overwritten globally */
.account :global(.MuiOutlinedInput-root) {
  border-radius: 100px;
}

.account {
  padding-bottom: 5.0667rem;
  width: 100%;
  color: #4d4d4d;
}

.card {
  padding: 1.6rem;
  border-radius: 20px;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-bottom: 1.0667rem;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1.0667rem;
  color: #e5004c;
}

.subtext {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.stripeLogo {
  height: 36px;
}

.actionBarContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}
