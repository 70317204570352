.layout {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  width: 100%;
}

.menu {
  display: flex;
  flex-direction: row;
  height: 40px;
}

.list {
  display: grid;
  grid-template-columns: 40% calc(60% - 16px);
  gap: 16px;
  align-items: start;
}

@media screen and (max-width: 600px) {
  .list {
    padding-bottom: 90px;
  }
}

/* expand to all columns if only one child */
.list > :only-child {
  grid-column: 1/-1;
}

.editTask {
  height: fit-content;
  position: sticky;
  top: 76px;
}
.editTaskDialog {
  padding: 8px;
}

.selectAll {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background-color: #e8f4f8;
  padding: 6px 16px;
  min-height: 48px;
  border-radius: 12px;
  color: #5f6368;
  border: 1px #add8e6 solid;
  font-size: 14px;
}
.selectAll :global(.material-icons) {
  color: orange;
}

.loadMore {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  border-top: 1px solid hsla(0, 0%, 90%);
}

.noTasks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
}
