.drawer :global(.MuiDrawer-paper) {
  width: 300px;
  border-radius: 0px 16px 16px 0px;
  padding: 16px 12px;
  gap: 8px;
}

.toolbar {
  display: "flex";
  flex-direction: "column";
}

.accountSwitcher {
  padding: 16px 24px 16px 16px;
  align-items: center;
  display: none;
}

@media screen and (max-width: 600px) {
  .accountSwitcher {
    display: flex;
  }
}
