.adSectionBox {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
}

.expansionPanel {
  transition: max-height 0.5s ease-out;
  max-height: 400px;
  overflow: hidden;
}

.expansionPanel-1000 {
  transition: max-height 0.5s ease-out;
  max-height: 1000px;
  overflow: hidden;
}

.expansionPanel.collapsible {
  max-height: 0px;
}

.expansionPanel-1000.collapsible {
  max-height: 0px;
}

.contactPopover {
  border-radius: 3px;
  box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border: 1px solid #cbd6e2;
  position: absolute;
  z-index: 9999;
  top: 42px;
  width: 100%;
}

.editNoteListSelectableItem:hover {
  background-color: #f5f8fa;
  cursor: pointer;
}

.editNoteListSelectableItem:focus {
  background-color: #f5f8fa;
  cursor: pointer;
}
