.ssoSegment {
  display: flex;
}

.integration {
  display: flex;
  height: 70px;
}

.ssoLabel {
  display: block;
  width: 8rem;
}

.ssoText {
  width: 20rem;
}

.icon {
  margin: 7px;
}
