.folderItem {
  display: flex;
  width: 100%;
  padding: 5px 0px 5px 10px;
  align-items: center;
}

.label {
  color: #5e5e5e;
}

.allLabel {
  color: #000;
  font-weight: bold;
}

.loading {
  flex: 1;
}

.edit {
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
}

.textBox {
  flex: 1;
  cursor: pointer;
}

.buttons {
  width: 36px;
}

.iconButton {
  font-size: 17px;
  padding: 0px;
}

.cancel {
  color: red;
}

.save {
  color: green;
}

.grey {
  color: grey;
}
