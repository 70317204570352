.admin {
  width: 100%;
  padding: 1.6rem 0 0 0;
  border-radius: 20px;
  box-shadow: 0px 2.5px 10px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.header {
  position: relative;
  color: var(--grey-100);
  margin: 0 1.6rem 1.6rem 1.6rem;
  display: flex;
  justify-content: space-between;
}

.header h1 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}

.header p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.tools {
  display: flex;
  gap: 0.8rem;
}

.fixedTable th:first-child,
.fixedTable td:first-child {
  padding: 0.933rem 1.6rem;
}

.fixedTable th:nth-child(1) {
  width: 180px;
}
.fixedTable th:nth-child(2) {
  width: 248px;
}
.fixedTable th:nth-child(3) {
  width: 96px;
}

.tableRow {
  cursor: pointer;
  content-visibility: auto;
  contain-intrinsic-size: 80px;
}

.fixedTable .tableRow td:last-child {
  text-align: right;
  color: #4d4d4d;
}

.fixedTable .tableRow:not(:last-child) td {
  border-bottom: solid 1px #e6eaed;
}

.fixedTable .tableRow:last-child td {
  border-bottom: none;
}

.fixedTable .tableRow:hover {
  background-color: #f7f7f7;
}

.fixedTable .tableRow:hover .toolbar {
  opacity: 1;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 3.2rem;
}

.avatar {
  height: 100%;
}

.toolbar {
  opacity: 0;
  transition: opacity 0.3s ease-out;
  white-space: nowrap;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.pauseButton,
.deleteButton {
  margin-left: 0.6rem;
}

.pauseButton:hover,
.deleteButton:hover {
  color: var(--primaryGrey);
  background-color: rgba(0, 0, 0, 0.05);
}

.deleteButton {
  margin-right: clamp(1rem, 10%, 3.2rem);
}

.deleteButton:disabled {
  cursor: not-allowed;
  pointer-events: auto;
  opacity: 0.5;
}

.dialogDeleteButton:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}

.chevron {
  vertical-align: middle;
}

.errorIcon {
  color: var(--error);
  margin-right: 10px;
}

.deleteAlertBlock {
  display: flex;
  align-items: center;
}
