.description_wrapper {
  width: 25%;
  margin-right: 2.6667rem;
}

.title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-bottom: 0.5rem;
}

.description_text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #808080;
}

.error_text {
  color: red;
}
