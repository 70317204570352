.root {
  display: flex;
}
.title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1.0667rem;
  color: #e5004c;
}

.contentWrapper {
  flex-grow: 1;
  width: calc(100% - 240px);
  padding: 80px 28px 0 28px;
  margin-bottom: 60px;
}

.errorCard {
  padding: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.errorCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  z-index: 1;
  background-color: white;
  box-shadow: 0px -2.5px 10px 0px rgba(0, 0, 0, 0.08);
  bottom: 0;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .footerTextContainer {
    display: flex;
    text-align: center;
  }
}
