.compliancePage h1 {
  margin: 60px 0px 10px 0px;
  font-size: 30px;
}

.compliancePage h2 {
  margin: 30px 0px 10px 0px;
  font-size: 20px;
}

.compliancePage p {
  margin: 16px 0px;
}

.compliancePage b {
  font-size: 16px;
}

.compliancePage li {
  margin: 10px 0px;
  font-size: 16px;
}
