.mergeTagsContainer {
  margin-top: 30px;
  padding: 5px;
}

.readOnlyTag {
  color: grey;
}

.mergeTagLabel {
  margin-right: 10px;
}

.mergeTagDescription {
  font-size: 14px;
  color: grey;
  max-width: 600px;
}

.mergeTagButton {
  font-size: 24px;
  color: #f76c6c;
  padding: 0px;
}

.saveContainer {
  margin-top: 30px;
}
