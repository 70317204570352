.wrapper {
  margin: 10px 0px;
}

.row {
  border: rgb(0, 164, 189) 2px solid;
  border-radius: 3px;
  background-color: #e8f4f7;
  padding: 12px;
}

.titleWrapper {
  display: flex;
}

.title {
  flex: 1;
  display: flex;
  align-items: center;

  & h4 {
    font-size: 16px;
    font-weight: 600;
  }
}

.buttonWrapper {
  margin-right: 8px;

  & span {
    font-size: 18px;
  }
}

.rulesWrapper {
  padding: 30px 30px 60px;
}

.ruleWrapper {
  display: flex;
  padding-top: 5px;

  & .label {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    & h3 {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.formWrapper {
  flex: 1;
  display: flex;
  align-items: center;
}

.dropdown {
  width: 100%;
  padding-right: 15px;
}

.rightDropdown {
  width: 100%;
  margin: 5px 0px;
}

.closeWrapper {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font-size: 18px;
  }
}

.addButton {
  margin: 10px 0px 0px 70px;

  & span {
    font-size: 18px;
  }

  & .buttonText {
    text-transform: capitalize;
    font-size: 14px;
    margin-left: 3px;
  }
}

.decoWrapper {
  padding-left: 70px;
  margin: 30px 0px 10px 0px;

  & i {
    font-size: 30px;
    color: #487cb4;
  }
}

.tagWrapper {
  display: flex;
  padding-top: 5px;
}

.tagTitle {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  & h3 {
    font-size: 18px;
    font-weight: bold;
  }
}
