.accountName {
  padding: 0 1rem;
  font-size: 0.8rem;
  line-height: 1.1;
}

@media screen and (max-width: 600px) {
  .accountSwitcher {
    width: 190px;
  }
}
